import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import SEO from '../layouts/Seo'
import FirmaBilgileri from '../contents/common/FirmaBilgileri'
import MapWrapperCard from '../components/cards/MapWrapperCard'

const IletisimPage = () => {
  return (
    <>
      <SEO
        title="İletişim - Erez Kaynak"
        description="İletişim Bilgilerimiz Firma Ünvanı : Erez Kaynak Uğur Erkin DEMİRKAYA Adres : İkitelli Org.San.Bölgesi Dersan Koop San.Sit. Trios 2023 B Blok Kat 3 No:137 Başakşehir / İSTANBUL İkitelli VD. V.No: 31198823454 Mersis No: 3119882345400001 Ticaret Sicil No: 102885-5 Tel : 0212 549 36 20GSM :0535 222 95 57 Mail:"
        keywords="hasır tel,hasır tel fiyatları,hasır tel imalatı,hasır tel kullanım alanları,kafes tel kullanım alanları,tel kafes kullanım alanları,paslanmaz hasır tel,hasır telleri,puntalı hasır tel,punta kaynaklı hasır tel,puntalı hasır tel fiyatları,hasır,tel,hasır tel imalatçısı,özel hasır tel,galvaniz hasır tel,hasır tel istanbul,tel hasır,kafes tel,tel hasır fiyatları,kafes tel fiyatları,hasır tel fiyatı"
        url="iletisim"
      />
      <Box paddingX={3} paddingY={5}>
        <Box mb={3}>
          <FirmaBilgileri />
        </Box>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <MapWrapperCard title="Google Haritada Yerimiz">
              <iframe
                title="Google Haritada Yerimiz"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3007.367142269452!2d28.787222015048798!3d41.08282422278367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa58abefcc16d%3A0x27f8a2b4aee2b1!2zRXJleiBLYXluYWsgw4dlbGlrIEhhc8SxciBUZWwgdmUgRmlsdHJlIEVsZWsgxLBtYWxhdMOnxLFzxLE!5e0!3m2!1str!2str!4v1575991627428!5m2!1str!2str"
                width="100%"
                height="400"
                frameborder="0"
                style={{ border: 0, filter: 'invert(85%)' }}
                allowfullscreen=""
              ></iframe>
            </MapWrapperCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <MapWrapperCard title="Yandex Haritada Yerimiz">
              <iframe
                title="Yandex Haritada Yerimiz"
                src="https://yandex.com.tr/map-widget/v1/?um=constructor%3A0531430fa51349cff69861e2a55eaebcd9d86223e64db863b9da07119a6dd6aa&amp;source=constructor"
                width="100%"
                height="400"
                frameborder="0"
                style={{ border: 0, filter: 'invert(85%)' }}
              ></iframe>
            </MapWrapperCard>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
export default IletisimPage
